import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import CaseStudyTemplate from '../../components/CaseStudyTemplate';

// assets
import Interaction1 from '../../images/case-study/pristeem/interaction1.mp4';
import Interaction2 from '../../images/case-study/pristeem/interaction2.mp4';
import Interaction3 from '../../images/case-study/pristeem/interaction3.mp4';
import Interaction4 from '../../images/case-study/pristeem/interaction4.mp4';
import Interaction5 from '../../images/case-study/pristeem/interaction5.mp4';
import Interaction6 from '../../images/case-study/pristeem/interaction6.mp4';
import Interaction7 from '../../images/case-study/pristeem/interaction7.mp4';
import Result from '../../images/case-study/pristeem/result.svg';

const PristeemCaseStudy = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "case-study/pristeem/logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      hero: file(relativePath: { eq: "case-study/pristeem/hero.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      problem: file(relativePath: { eq: "case-study/pristeem/problem.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  const caseStudyData = {
    logo: data.logo.childImageSharp.gatsbyImageData,
    name: 'Pristeem',
    date: 'April 2021',
    title: (
      <div className="banner__title">
        On-demand, self-service clothing{' '}
        <span className="banner__title--highlight">care</span> amenity
      </div>
    ),
    platforms: ['phone', 'kiosk'],
    appLinks: [
      { type: 'play-store', path: '/' },
      { type: 'app-store', path: '/' },
    ],
    thumbnail: data.hero.childImageSharp.gatsbyImageData,
    description:
      'Have you ever been concerned about the amount of resources required to launder and dry clean your clothes? Or consider how much these procedures degrade the quality of your clothing and how time-consuming they are?',
    expandCTA: 'Start the process',
    problem: [
      {
        text: 'Are you a naturist or someone who’s concerned about our beautiful Earth, then you’ll probably know that how much the whole laundry system affects our planet as well as you. Water wastage, electricity wastage, time wastage and a lot more (which is very terrifying).',
        image: Interaction1,
      },
      {
        text: 'Target was to create an eco-friendly system which will fasten the laundry process (yup, an all new amazing system). User will be able to schedule the process from the nearest Kiosk or through the mobile application (sounds interesting, right?).',
        image: data.problem.childImageSharp.gatsbyImageData,
      },
    ],
    method: {
      text: 'Introducing a new system for the users was a tough task, we had to come up with something which will make the users to adapt the new system through the app as well as the Kiosk.',
      image: Interaction2,
    },
    design: [
      {
        title: 'MOBILE APP',
        text: 'Easy to use and a user friendly interface is our first choice, every complex product needs a simple interface (yes, we know what our users are thinking).',
        image: Interaction3,
      },
      { imageOne: Interaction4, imageTwo: Interaction5 },
      {
        title: 'KIOSK',
        text: 'We worked on the interface of the Kiosk as well, making a delightful experience for the users who are visiting it.',
        image: Interaction6,
      },
      {
        text: 'The webapp helps you create, organize and manage tasks efficiently with a clean dashboard implementation',
        image: Interaction7,
      },
    ],
    result: {
      text: 'With an amazing product, we enjoyed the whole process and came up with this solution.”Every process is worth enjoying”',
      image: Result,
    },
    next: ['moja', 'flexnest'],
  };

  return (
    <>
      <CaseStudyTemplate {...caseStudyData} />
    </>
  );
};

export default PristeemCaseStudy;
